.advantages {
	margin-top: 220px;
	.resp(1200px,{
		margin-top: 100px;
	});
	.container {
		position: relative;
		display: flex;
		justify-content: flex-end;
		.resp(1200px,{
			display: block;
		});
	}
}

.advantages-box {
	flex: 0 0 668px;
	max-width: 668px;
	.resp(1200px,{
		max-width: none;
		margin-left: 470px;
	});
	.resp(999px,{
		margin-left: 330px;
	});
	.resp(767px,{
		margin: 0 0 80px 0;
	});
	> h2 {
		margin-bottom: 57px;
		.resp(1200px,{
			margin-bottom: 40px;
		});
	}
}

.advantages-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	> li {
		margin-left: 101px;
		position: relative;
		padding-top: 17px;
		.resp(479px,{
			margin-left: 70px;
		});
		& + li {
			margin-top: 40px;
			border-top: 1px solid @gray;
		}
		&:before {
			position: absolute;
			left: -101px;
			top: 17px;
			width: 76px;
			height: 77px;
			content: '';
			background-repeat: no-repeat;
			background-size: 100% 100%;
			.resp(479px,{
				width: 54px;
				height: 54px;
				left: -70px;
			});
		}
		> span {
			min-height: 70px;
			display: flex;
			align-items: center;
			height: 1px;
			.resp(479px,{
				min-height: 54px;
			});
			> span {
				flex: 1 1 100%;
				max-width: 100%;
				font-weight: bold;
				display: block;
				line-height: 30px;
				font-size: 30px;
				.resp(999px,{
					font-size: 26px;
					line-height: 26px;
				});
				.resp(479px,{
					font-size: 22px;
					line-height: 24px;
				});
			}
		}
		> p {
			font-size: 22px;
			line-height: 29px;
			margin: 15px 0 0 0;
			.resp(1200px,{
				font-size: 20px;
				line-height: 28px;
			});
			.resp(479px,{
				font-size: 18px;
				line-height: 26px;
				margin-top: 10px;
			});
		}
	}
	.advantages-design {
		&:before {
			background-image: url('../img/advantages-design.svg');
		}
	}
	.advantages-new {
		&:before {
			background-image: url('../img/advantages-new.svg');
		}
	}
	.advantages-source {
		&:before {
			background-image: url('../img/advantages-source.svg');
		}
	}
}

.advantages-phone {
	width: 382px;
	height: 760px;
	box-sizing: border-box;
	padding: 20px 25px 14px 25px;
	position: absolute;
	top: -20px;
	left: 37px;
	box-shadow: -38px 38px 60px fade(#000,10%), 10px -10px 10px fade(#000,10%) inset;
	border-radius: 60px;
	transform: rotate(-6.47deg);
	.resp(999px,{
		left: -103px;
		top: 10px;
	});
	.resp(800px,{
		top: 40px;
	});
	.resp(767px,{
		position: relative;
		top: 0;
		left: 0;
		margin: 0 auto;
	});
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		background: url('../img/phone.png') no-repeat;
		background-size: 100% 100%;
		z-index: 3;
		.retina({
			background-image: url('../img/phone@2x.png');
		});
	}
	&:before {
		position: absolute;
		width: 423px;
		height: 424px;
		top: 198px;
		left: -150px;
		content: '';
		z-index: 1;
		background: url('../img/figure-green-advantages.svg') no-repeat;
		background-size: 100% 100%;
		transform: rotate(6.47deg);
	}
	> img {
		width: 332px;
		height: 726px;
		position: relative;
		z-index: 2;
	}
}