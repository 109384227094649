html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
	margin: 0;
    position: relative;
    font-size: 19px;
	line-height: 27px;
	font-family: @font;
	color: @black;
    background: #FFF;
	-webkit-text-size-adjust: 100%;
	min-width: 320px;
	min-height: 100%;
	display: flex;
	align-items: stretch;
	.resp(999px,{
		font-size: 16px;
		line-height: 22px;
	});
}

img {
	border: 0;
	max-width: 100%;
}

form,
fieldset {
	margin: 0;
	padding: 0;
	border: 0 none;
}

a {
	outline: 0;
	color: @green;
	text-decoration: none;
	transition: color @hover;
	&:hover {
		color: @black;
	}
}

h2 {
	font-size: 50px;
	line-height: 50px;
	margin: 0 0 25px 0;
	font-weight: bold;
	.resp(999px,{
		font-size: 36px;
		line-height: 36px;
	});
	.resp(767px,{
		font-size: 32px;
		line-height: 32px;
	});
	.resp(479px,{
		font-size: 28px;
		line-height: 30px;
	});
	> span {
		color: @green;
	}
}

h3 {
	font-size: 28px;
	line-height: 28px;
	margin: 0 0 27px 0;
	font-weight: bold;
	.resp(767px,{
		font-size: 24px;
		line-height: 24px;
		margin-bottom: 20px;
	});
}

hr {
	height: 1px;
	border: none;
	color: #DFDFE0;
	background: #DFDFE0;
	margin: 100px 0;
	.resp(999px,{
		margin: 60px 0;
	});
	.resp(767px,{
		margin: 40px 0;
	});
}

p {
	margin: 14px 0;
	.margin;
}

.break-word {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}

.center {
	text-align: center;
}

.contact-mail {
	padding-left: 34px;
	position: relative;
	&:before {
		position: absolute;
		left: 0;
		top: 7px;
		width: 21px;
		height: 16px;
		content: '';
		background: url('../img/mail.svg') no-repeat;
		background-size: 100% 100%;
	}
}

.container {
	max-width: 1200px;
	width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.container-small {
	max-width: 880px;
	width: 100%;
	margin: 0 auto;
}

.content {
	padding-bottom: 72px;
	.resp(999px,{
		padding: 20px 0;
	});
}

.col {
	max-width: 880px;
	width: 100%;
	margin: 0 auto;
}

.margin {
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.site {
	position: relative;
	z-index: 2;
}

.wrapper {
	flex: 1 1 100%;
	max-width: 100%;
	overflow: hidden;
	position: relative;
	.homepage & {
		&:before {
			top: -508px;
			transform: translateX(-1050px);
		}
	}
	&:before {
		position: absolute;
		width: 1550px;
		height: 1547px;
		left: 50%;
		top: -837px;
		content: '';
		z-index: 1;
		background: url('../img/figure-gray.svg') no-repeat;
		background-size: 100% 100%;
		transform: translateX(-938px);
	}
}