@font: 'Mariupol', 'Arial', 'Helvetica', 'Nimbus Sans L', sans-serif;

@hover: 0.2s ease;
@move: 0.3s ease-in-out;

@black: #151622;
@green: #2ABE8F;
@gray: #E5E3E3;
@border: #F3F3F3;

@retina: ~'(min-resolution: 124dpi), (-webkit-min-device-pixel-ratio: 1.3), (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 4/3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx)';