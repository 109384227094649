.text {
	h1 {
		text-align: center;
		font-size: 60px;
		line-height: 60px;
		margin: 0 0 40px 0;
		.resp(999px,{
			font-size: 50px;
			line-height: 50px;
			margin-bottom: 34px;
		});
		.resp(767px,{
			font-size: 36px;
			line-height: 36px;
			margin-bottom: 30px;
		});
		.resp(419px,{
			font-size: 32px;
			line-height: 32px;
		});
	}
	h2 {
		font-size: 28px;
		line-height: 28px;
		margin-bottom: 16px;
		.resp(767px,{
			font-size: 24px;
			line-height: 24px;
		});
	}
	h3 {
		margin-bottom: 16px;
	}
	p {
		& + h2,
		& + h3 {
			margin-top: 60px;
			.resp(767px,{
				margin-top: 40px;
			});
		}
	}
	ul {
		margin: 14px 0;
		padding: 0;
		list-style-type: none;
		.margin;
		& + h2,
		& + h3 {
			margin-top: 60px;
			.resp(767px,{
				margin-top: 40px;
			});
		}
		> li {
			padding-left: 23px;
			position: relative;
			& + li {
				margin-top: 10px;
			}
			&:before {
				position: absolute;
				left: 2px;
				top: 8px;
				width: 9px;
				height: 9px;
				content: '';
				background: @green;
				transform: rotate(45deg);
			}
		}
	}
	.lead-text {
		font-size: 22px;
		line-height: 31px;
		margin-bottom: 88px;
		.resp(999px,{
			margin-bottom: 60px;
		});
		.resp(767px,{
			margin-bottom: 40px;
			font-size: 18px;
			line-height: 24px;
		});
	}
}