.about {
	text-align: center;
	overflow: hidden;
	.resp(1200px,{
		height: 1050px;
		border-bottom: 1px solid @border;
	});
	.resp(767px,{
		height: auto;
		margin-bottom: 80px;
		border: 0;
	});
	p {
		max-width: 700px;
		font-size: 28px;
		line-height: 34px;
		margin: 0 auto 74px;
		.resp(999px,{
			font-size: 24px;
			line-height: 30px;
			margin: 0 auto 50px;
			max-width: 600px;
		});
		.resp(767px,{
			font-size: 20px;
			line-height: 28px;
			max-width: 520px;
		});
		.resp(479px,{
			font-size: 18px;
			line-height: 26px;
			max-width: 340px;
		});
	}
}

.about-list {
	margin: 0 -20px;
	padding: 0;
	list-style-type: none;
	display: flex;
	.resp(1200px,{
		display: block;
		margin: 0 auto;
		max-width: 880px;
	});
	> li {
		padding: 0 20px;
		box-sizing: border-box;
		max-width: 33.333%;
		flex: 0 0 33.3333%;
		.resp(1200px,{
			max-width: none;
			display: flex;
			& + li {
				margin-top: -460px;
				.about-phone {
					transform: translateX(40px);
				}
				& + li {
					.about-phone {
						transform: translateX(-20px);
					}
				}
			}
		});
		.resp(767px,{
			display: block;
			position: relative;
			padding-top: 710px;
			& + li {
				margin-top: 80px;
				.about-phone {
					transform: translateX(-50%);
				}
				& + li {
					.about-phone {
						transform: translateX(-50%);
					}
				}
			}
		});
		.about-phone-text {
			.resp(1200px,{
				flex: 1 1 auto;
				padding: 90px 60px 0 90px;
			});
			.resp(999px,{
				padding: 90px 40px 0 80px;
			});
			.resp(767px,{
				padding: 0;
				max-width: 320px;
				margin: 0 auto;
			});
			> p {
				font-size: 22px;
				line-height: 29px;
				margin: 21px 0 0 0;
				.resp(999px,{
					font-size: 18px;
					line-height: 24px;
					margin-top: 16px;
				});
			}
			> span {
				display: block;
				font-weight: bold;
				font-size: 32px;
				line-height: 32px;
				.resp(999px,{
					font-size: 24px;
					line-height: 26px;
				});
			}
		}
	}
	.about-phone {
		width: 335px;
		height: 667px;
		margin: 0 auto 62px;
		box-sizing: border-box;
		padding: 15px 21px 18px 21px;
		position: relative;
		box-shadow: -38px 38px 60px fade(#000,10%), 10px -10px 10px fade(#000,10%) inset;
		border-radius: 50px;
		.resp(1200px,{
			flex: 0 0 335px;
			max-width: 335px;
		});
		.resp(767px,{
			position: absolute;
			left: 50%;
			top: 0;
			margin: 0;
			transform: translateX(-50%);
		});
		&:after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: '';
			background: url('../img/phone.png') no-repeat;
			background-size: 100% 100%;
			.retina({
				background-image: url('../img/phone@2x.png');
			});
		}
		> img {
			width: 293px;
			height: 634px;
		}
	}
}