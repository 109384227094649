.popup {
	background: fade(#FFF,80%);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 10px;
	box-sizing: border-box;
	z-index: 1000;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: none;
	overflow: hidden;
	&.is-opened {
		display: flex;
	}
	.popup-box {
		max-width: 100%;
		width: 480px;
		max-height: 100%;
		flex: 0 1 auto;
		position: relative;
		overflow: auto;
		background: #FFF;
		box-shadow: 0px 4px 30px rgba(21, 55, 43, 0.16);
		border-radius: 20px;
		padding: 43px 40px;
		box-sizing: border-box;
		.resp(479px,{
			padding: 40px 20px 20px 20px;
		});
		> p {
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			max-width: 372px;
			margin: 23px auto;
			.resp(479px,{
				font-size: 18px;
				line-height: 22px;
				margin: 16px 0;
			});
		}
	}
	.popup-close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 16px;
		height: 16px;
		cursor: pointer;
		margin: 0;
		padding: 0;
		outline: 0;
		cursor: pointer;
		border: 0;
		-webkit-border-radius: 0;
		-webkit-appearance: none;
		transition: background @hover;
		.svg('close',@gray);
		.resp(479px,{
			width: 12px;
			height: 12px;
			top: 16px;
			right: 16px;
		});
		&:hover {
			.svg('close',@green);
		}
	}
	.popup-header {
		font-size: 32px;
		line-height: 38px;
		text-align: center;
		margin-bottom: 14px;
		font-weight: bold;
		.resp(767px,{
			font-size: 28px;
			line-height: 28px;
		});
		.resp(419px,{
			font-size: 24px;
			line-height: 24px;
		});
		& + p {
			margin-top: 0;
		}
	}
}