@font-face {
    font-family: 'Mariupol';
    src: url('../font/Mariupol-Regular.eot');
    src: local('Mariupol'), local('Mariupol-Regular'),
        url('../font/Mariupol-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/Mariupol-Regular.woff2') format('woff2'),
        url('../font/Mariupol-Regular.woff') format('woff'),
        url('../font/Mariupol-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mariupol';
    src: url('../font/Mariupol-Medium.eot');
    src: local('Mariupol Medium'), local('Mariupol-Medium'),
        url('../font/Mariupol-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/Mariupol-Medium.woff2') format('woff2'),
        url('../font/Mariupol-Medium.woff') format('woff'),
        url('../font/Mariupol-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mariupol';
    src: url('../font/Mariupol-Bold.eot');
    src: local('Mariupol Bold'), local('Mariupol-Bold'),
        url('../font/Mariupol-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/Mariupol-Bold.woff2') format('woff2'),
        url('../font/Mariupol-Bold.woff') format('woff'),
        url('../font/Mariupol-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

