header {
	padding: 68px 0 107px 0;
	position: relative;
	z-index: 10;
	.resp(999px,{
		padding: 40px 0;
	});
	.container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		.resp(999px,{
			align-items: center;
		});
	}
	.lang {
		flex: 0 0 auto;
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		.resp(767px,{
			padding-top: 24px;
		});
		> li {
			flex: 0 0 auto;
			font-size: 20px;
			line-height: 28px;
			font-weight: 500;
			.resp(767px,{
				font-size: 18px;
			});
			& + li {
				margin-left: 13px;
				.resp(479px,{
					margin-left: 10px;
				});
			}
			> a {
				display: block;
				margin: 0 3px;
				color: fade(@black,50%);
				&:hover {
					color: @green;
				}
			}
			> span {
				display: block;
				cursor: default;
				position: relative;
				padding: 0 3px;
				&:before {
					position: absolute;
					left: 0;
					bottom: -1px;
					width: 100%;
					height: 4px;
					background: @green;
					border-radius: 2px;
					content: '';
				}
			}
		}
	}
	.logo {
		width: 156px;
		height: 65px;
		display: block;
		flex: 0 0 auto;
		background: url('../img/logo.svg') no-repeat;
		background-size: 100% 100%;
		.resp(767px,{
			width: 117px;
			height: 49px;
		});
	}
}