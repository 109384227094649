.app {
	margin-top: 200px;
	.resp(1200px,{
		margin-top: 100px;
	});
	.resp(999px,{
		margin-top: 140px;
	});
	.resp(767px,{
		margin-top: 100px;
	});
}

.app-box {
	background: linear-gradient(248.61deg, #2BC090 2.67%, #179E86 98.22%);
	border-radius: 60px;
	padding: 92px 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.resp(1200px,{
		padding: 60px;
	});
	.resp(767px,{
		padding: 40px;
		display: block;
		text-align: center;
	});
	.resp(639px,{
		padding: 30px;
	});
	.resp(479px,{
		padding-bottom: 40px;
	});
	> h2 {
		color: #FFF;
		flex: 1 1 auto;
		max-width: 600px;
		margin: 0;
		font-size: 55px;
		line-height: 55px;
		.resp(1200px,{
			font-size: 42px;
			line-height: 48px;
		});
		.resp(900px,{
			font-size: 36px;
			line-height: 40px;
		});
		.resp(767px,{
			font-size: 32px;
			line-height: 32px;
			margin-bottom: 40px;
		});
		.resp(639px,{
			font-size: 24px;
			line-height: 24px;
			margin-bottom: 36px;
		});
		.resp(419px,{
			font-size: 22px;
		});
	}
	.app-list {
		flex: 0 0 260px;
		max-width: 260px;
		margin: 0 0 0 60px;
		padding: 0;
		list-style-type: none;
		.resp(900px,{
			flex: 0 0 240px;
			max-width: 240px;
			margin-left: 40px;
		});
		.resp(767px,{
			display: flex;
			margin: -20px;
			justify-content: center;
			max-width: none;
		});
		.resp(639px,{
			margin: -10px;
		});
		.resp(479px,{
			display: block;
			margin: 0;
		});
		> li {
			.resp(767px,{
				flex: 0 0 auto;
				margin: 20px;
			});
			.resp(639px,{
				margin: 10px;
			});
			.resp(479px,{
				margin: 0 auto;
				max-width: 200px;
			});
			&:hover {
				position: relative;
				&:before {
					position: absolute;
					width: 38px;
					height: 48px;
					content: '';
					top: -36px;
					left: -36px;
					background: url('../img/app-active-figure.svg') no-repeat;
					background-size: 100% 100%;
					.resp(639px,{
						width: 19px;
						height: 24px;
						top: -18px;
						left: -18px;
					});
				}
			}
			& + li {
				margin-top: 44px;
				.resp(900px,{
					margin-top: 30px;
				});
				.resp(767px,{
					margin: 20px;
				});
				.resp(639px,{
					margin: 10px;
				});
				.resp(479px,{
					margin: 20px auto 0;
				});
			}
			> a {
				display: block;
				> img {
					width: 100%;
					display: block;
					.resp(767px,{
						width: auto;
						height: 60px;
					});
					.resp(639px,{
						height: 50px;
					});
					.resp(479px,{
						height: auto;
						width: 100%;
					});
				}
			}
		}
	}
}