button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.button {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	color: #FFF;
	font-size: 22px;
	line-height: 34px;
	text-align: center;
	font-family: @font;
	padding: 15px;
	font-weight: bold;
	background: @green;
	text-decoration: none;
	border-radius: 10px;
	-webkit-appearance: none;
	overflow: visible;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
    cursor: pointer;
	border: 0;
	transition: background @hover;
	.resp(767px,{
		padding: 10px;
		font-size: 20px;
	});
	&:hover {
		background: lighten(@green,5%);
		color: #FFF;
	}
	&:active {
		background: darken(@green,5%);
	}
	&.button-block {
		width: 100%;
		display: block;
	}
}