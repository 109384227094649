.textarea {
    height: 158px;
    resize: none;
    overflow: auto;
}

.textfield {
    height: 58px;
}

.textarea,
.textfield {
    border-radius: 10px;
    border: 2px solid @border;
    width: 100%;
    box-sizing: border-box;
    display: block;
    margin: 0;
    outline: 0;
    padding: 15px 18px;
    font-size: 18px;
    line-height: 24px;
    background: #FFF;
    font-family: @font;
    color: @black;
    -webkit-appearance: none;
    .placeholder(fade(@black,50%));
    &:focus {
        border-color: @green;
    }
}