.form {
	fieldset {
		margin-bottom: 12px;
	}
	.form-button {
		padding-top: 23px;
		.resp(767px,{
			padding: 0;
		});
	}
}