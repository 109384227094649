.intro {
	min-height: 782px;
	padding-top: 37px;
	box-sizing: border-box;
	.resp(1279px,{
		padding: 0;
		min-height: 500px;
	});
	.resp(999px,{
		padding-top: 60px;
		min-height: 580px;
	});
	.resp(900px,{
		padding-top: 40px;
		min-height: 600px;
	});
	.resp(767px,{
		min-height: 0;
		padding: 20px 0 80px 0;
	});
	.container {
		position: relative;
	}
	.intro-app {
		margin: -18px;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		.resp(1279px,{
			margin: -10px;
		});
		.resp(767px,{
			justify-content: center;
		});
		.resp(479px,{
			display: block;
			margin: 0;
		});
		> li {
			margin: 18px;
			flex: 0 0 auto;
			.resp(1279px,{
				margin: 10px;
			});
			.resp(479px,{
				max-width: 200px;
				margin: 0 auto;
				& + li {
					margin-top: 20px;
				}
			});
			> a {
				display: block;
				> img {
					display: block;
					height: 86px;
					.iefix({
						width: 100%;
					});
					.resp(1279px,{
						height: 72px;
					});
					.resp(900px,{
						height: auto;
						width: 220px;
					});
					.resp(767px,{
						width: auto;
						height: 60px;
					});
					.resp(479px,{
						height: auto;
						width: 100%;
					});
				}
			}
		}
	}
	.intro-box {
		max-width: 720px;
		.resp(1279px,{
			max-width: 540px;
		});
		.resp(900px,{
			max-width: 420px;
		});
		.resp(767px,{
			margin: 0 auto;
			text-align: center;
			max-width: 480px;
		});
		> h1 {
			font-size: 74px;
			line-height: 74px;
			margin: 0 0 35px 0;
			font-weight: bold;
			.resp(1279px,{
				font-size: 56px;
				line-height: 56px;
			});
			.resp(900px,{
				font-size: 40px;
				line-height: 40px;
			});
			.resp(479px,{
				font-size: 32px;
				line-height: 32px;
			});
		}
		> p {
			font-size: 32px;
			line-height: 45px;
			margin-bottom: 88px;
			max-width: 670px;
			.resp(1279px,{
				font-size: 24px;
				line-height: 30px;
				margin-bottom: 50px;
				max-width: 500px;
			});
			.resp(900px,{
				font-size: 24px;
				line-height: 30px;
				margin-bottom: 40px;
				max-width: 380px;
			});
			.resp(767px,{
				max-width: 480px;
			});
			.resp(539px,{
				font-size: 22px;
				line-height: 28px;
			});
			.resp(479px,{
				font-size: 20px;
				line-height: 26px;
			});
		}
	}
	.intro-phone {
		width: 335px;
		height: 667px;
		position: absolute;
		right: 63px;
		top: -107px;
		box-shadow: -38px 38px 60px fade(#000,10%), 10px -10px 10px fade(#000,10%) inset;
		border-radius: 50px;
		transform: rotate(9.07deg);
		.resp(1279px,{
			width: 268px;
			height: 533px;
			right: 120px;
		});
		.resp(999px,{
			right: 40px;
			top: -80px;
		});
		.resp(900px,{
			top: -40px;
			right: 60px;
		});
		.resp(767px,{
			display: none;
		});
		&:after {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: '';
			background: url('../img/phone.png') no-repeat;
			background-size: 100% 100%;
			z-index: 3;
			.retina({
				background-image: url('../img/phone@2x.png');
			});
		}
		&:before {
			position: absolute;
			width: 504px;
			height: 504px;
			top: 34px;
			right: -180px;
			content: '';
			z-index: 1;
			background: url('../img/figure-green-intro.svg') no-repeat;
			background-size: 100% 100%;
			transform: rotate(-9.07deg);
			.resp(1279px,{
				width: 404px;
				height: 404px;
			});
		}
		.intro-phone-logo {
			position: absolute;
			left: 18px;
			top: 10px;
			width: 293px;
			height: 634px;
			background: #FFF url('../img/logo.svg') no-repeat center center;
			background-size: 160px 67px;
			z-index: 2;
			.resp(1279px,{
				width: 234px;
				height: 508px;
			});
		}
	}
}