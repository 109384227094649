footer {
	padding: 86px 0 106px 0;
	font-size: 18px;
	line-height: 28px;
	.resp(999px,{
		padding: 40px 0;
	});
	.resp(767px,{
		font-size: 16px;
		line-height: 22px;
	});
	.container {
		display: flex;
		align-items: flex-start;
		.resp(1100px,{
			display: block;
			position: relative;
			text-align: right;
		});
		.resp(639px,{
			text-align: center;
		});
		&:after {
			display: block;
			flex: 1 1 auto;
			content: '';
			order: 3;
			.resp(1100px,{
				display: none;
			});
		}
	}
	.footer-contact {
		flex: 0 0 auto;
		order: 4;
		.resp(767px,{
			margin-top: 24px;
		});
		.resp(419px,{
			max-width: 200px;
			margin-left: auto;
			margin-right: auto;
		});
	}
	.footer-links {
		flex: 0 0 auto;
		order: 2;
		margin: 24px 0 0 56px;
		padding: 0;
		list-style-type: none;
		display: flex;
		.resp(1100px,{
			margin: 0;
			justify-content: flex-end;
		});
		.resp(639px,{
			justify-content: center;
			margin-top: 24px;
		});
		.resp(479px,{
			display: block;
			margin-top: 30px;
		});	
		& + .footer-contact {
			margin-top: 24px;
			.resp(999px,{
				margin-top: 12px;
			});
		}
		> li {
			flex: 0 0 auto;
			& + li {
				padding-left: 32px;
				position: relative;
				.resp(479px,{
					padding: 8px 0 0 0;
				});
				&:before {
					position: absolute;
					left: 14px;
					top: 0;
					content: '·';
					.resp(479px,{
						display: none;
					});
				}
			}
			> a {
				color: @black;
				&:hover {
					color: @green;
				}
			}
		}
	}
	.logo {
		width: 105px;
		height: 44px;
		display: block;
		flex: 0 0 auto;
		background: url('../img/logo.svg') no-repeat;
		background-size: 100% 100%;
		order: 1;
		.resp(1100px,{
			position: absolute;
			left: 20px;
			top: 50%;
			margin-top: -22px;
		});
		.resp(639px,{
			position: relative;
			left: 0;
			top: 0;
			margin: 0 auto;
		});
	}
}